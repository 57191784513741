import { createContext } from "react";

export type Environment = {
  google: {
    clientId: string;
    hostedDomain: string;
  };
};

export const EnvironmentContext = createContext<Environment>({
  google: {
    clientId: "",
    hostedDomain: "",
  },
});

import "./Login.css";
import Logo from "./Logo";
import SignInButton from "./SignInButton";
import React from "react";
import useGoogleAuth from "../hooks/useGoogleAuth";

export default function Login() {
  const { isSignedIn, isLoading, error } = useGoogleAuth();

  return (
    <div className="login-container">
      <div className="login-panel">
        <div className="login-content">
          <Logo />
          {error && <p id="message">{error}</p>}
          {!isSignedIn && !isLoading && <SignInButton />}
        </div>
      </div>
    </div>
  );
}

import "./Background.css";
import { ReactNode } from "react";

export type BackgroundProps = {
  children: ReactNode;
};

export default function Background({ children }: BackgroundProps) {
  return <div className="login-background">{children}</div>;
}

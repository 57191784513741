import logo from "../assets/logo.svg";

export type LogoProps = {
  size?: number;
  className?: string;
};

export default function Logo({ size = 64, className }: LogoProps) {
  return (
    <img
      src={logo}
      className={className}
      height={size}
      alt="Polyteknikkojen Orkesteri"
    />
  );
}

import React from "react";
import Login from "./components/Login";
import Background from "./components/Background";

export default function App() {
  return (
    <Background>
      <Login />
    </Background>
  );
}

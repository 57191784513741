import { useCallback } from "react";

const STORAGE_KEY = "iap-return";

const getReturnUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  const returnUrl = urlParams.get("return");

  if (returnUrl) {
    sessionStorage.setItem(STORAGE_KEY, returnUrl);
    return returnUrl;
  } else {
    return sessionStorage.getItem(STORAGE_KEY);
  }
};

export default function useReturnUrl() {
  const returnUrl = getReturnUrl();

  const validateRedirect = (value: string) => {
    const allowed = new URL(window.location.href);

    let url;

    try {
      url = new URL(value);
    } catch (error) {
      return false;
    }

    if (allowed.hostname !== "localhost" && url.protocol !== "https:") {
      return false;
    } else if (value.indexOf("\r") !== -1) {
      return false;
    } else if (value.indexOf("\n") !== -1) {
      return false;
    }

    return url.host === allowed.host;
  };

  const redirectToReturnUrl = useCallback(() => {
    sessionStorage.removeItem(STORAGE_KEY);

    if (returnUrl === null || returnUrl === "/") {
      window.location.replace("/");
    } else if (validateRedirect(returnUrl)) {
      window.location.replace(returnUrl);
    } else {
      throw new Error(`Invalid redirect to ${returnUrl}`);
    }
  }, [returnUrl]);

  return { redirectToReturnUrl };
}

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Environment, EnvironmentContext } from "./context/environment";

const loadDynamicEnvironment = async (): Promise<Environment> => {
  const response = await fetch("/login/environment.json");
  return await response.json();
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

loadDynamicEnvironment().then((environment) => {
  root.render(
    <React.StrictMode>
      <EnvironmentContext.Provider value={environment}>
        <App />
      </EnvironmentContext.Provider>
    </React.StrictMode>
  );
});

import { useCallback, useEffect, useState } from "react";
import useEnvironment from "./useEnvironment";
import useReturnUrl from "./useReturnUrl";

const setIdTokenCookie = (idToken: string, expiresIn: number) => {
  const cookie = [
    `id_token=${idToken}`,
    ";path=/",
    ";samesite=strict",
    `;max-age=${expiresIn}`,
  ];

  if (window.location.hostname !== "localhost") {
    cookie.push(";secure");
  }

  document.cookie = cookie.join(" ");
};

const getIdTokenCookie = (): string | null => {
  const result = document.cookie.match(/id_token=([^;]+)/);

  if (!result) {
    return null;
  }

  return result[1].trim();
};

export default function useGoogleAuth() {
  const {
    google: { clientId },
  } = useEnvironment();
  const { redirectToReturnUrl } = useReturnUrl();
  const [idToken, setIdToken] = useState(getIdTokenCookie);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isSignedIn = idToken !== null;

  const handleCredentialResponse = useCallback(
    ({ credential }: google.accounts.id.CredentialResponse) => {
      setIdToken(credential);
      setIdTokenCookie(credential, 3600);
    },
    []
  );

  useEffect(() => {
    if (isSignedIn) {
      try {
        redirectToReturnUrl();
      } catch (error) {
        console.error(error);
        setError("Redirect error");
      }
    }
  }, [isSignedIn, redirectToReturnUrl]);

  useEffect(() => {
    if (isSignedIn) {
      return;
    }

    const handleOnLoad = () => {
      // TODO: error handling
      google.accounts.id.initialize({
        client_id: clientId,
        auto_select: true,
        context: "signin",
        callback: handleCredentialResponse,
      });

      setIsLoading(false);
      google.accounts.id.prompt();
    };

    setIsLoading(true);

    const body = document.getElementsByTagName("body")[0];
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.onload = handleOnLoad;
    body.appendChild(script);

    return () => {
      body.removeChild(script);
    };
  }, [isSignedIn, clientId, handleCredentialResponse]);

  return { isSignedIn, isLoading, error };
}

import React, { useEffect, useRef } from "react";

export default function SignInButton() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && window.google) {
      window.google.accounts.id.renderButton(ref.current, {
        type: "standard",
        theme: "outline",
        shape: "rectangular",
      });
    }
  }, [ref]);

  return <div ref={ref} />;
}
